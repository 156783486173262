import React from 'react'
import { Layout, Avatar, Badge } from 'antd'

import { useGlobalContext } from '../AppContext'

import Icon from '../Icon'
import AllowRender from '../AllowRender'
import CenterSelect from '../CenterSelect'
import Notification from '../Notification'

import './index.css'

const { Header } = Layout

export default function PageTopBar({ children, toogleChat, unreadChatMessages = 0 }) {
	const { globals } = useGlobalContext()

	const userCanChat = globals.user.permissions.includes('CHAT')

	const style = { height: '50px' }
	return (
		<div style={{ width: '100%' }}>
			<Header className="page-top-bar flex" style={style}>
				{AllowRender({ permissions: ['HSP_RW'], inverted: true, truthy: true, level: 2 }) && (
					<>
						<AllowRender permissions={['HSP_RW']} inverted={true}>
							<div className="flex" style={{ lineHeight: '50px' }}>
								<CenterSelect style={{ width: 200 }} />
							</div>
						</AllowRender>
						<AllowRender permissions={['HSP_RW']} exactMatch={true}>
							<div className="header-user-menu align-right">
								<div className="user-action-menu">
									<div className={userCanChat ? '' : 'disabled-btn'} disabled={true} onClick={() => toogleChat()}>
										<Badge count={userCanChat ? unreadChatMessages : null} color="green" offset={[5, 19]}>
											<Icon type={'chat'} />
										</Badge>
									</div>

									<AllowRender permissions={['HSP_RW']} inverted={true}>
										<Notification></Notification>
									</AllowRender>
								</div>
							</div>
						</AllowRender>
						<div className="user">
							<Avatar />
							<span className="ant-text medium ml10">
								{globals?.user?.name ? globals.user.name : globals?.user?.hsp?.name ? globals.user.hsp.name : ''}
							</span>
						</div>
					</>
				)}
			</Header>
			{children}
		</div>
	)
}
